import DashboardBlock from '../../components/client-dashboard/DashboardBlock';
import { StatsBlock, ChangeMode } from '../../components/client-dashboard/StatsBlock';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../components/shared/Tooltip';
import ChartDataSourceSelector from '../../components/client-dashboard/ChartDataSourceSelector';
import NoDataPlaceholder from '../../components/client-dashboard/NoData';
import BarChart, { BarChartType } from '../../components/shared/charts/BarChart';
import DoughnutChart from '../../components/shared/charts/DoughnutChart';
import { useCallback, useState } from 'react';
import { ChartDataSet } from '../../components/shared/charts/ChartDataSet';
import FunnelIcon from '../../components/shared/icon/FunnelIcon';

type TrainingEventsStats = {
  total: number;
  scheduled: number;
  scheduledPercentage: number;
  completed: number;
  completedPercent: number;
  inSession: number;
  inSessionProgress: number;
  blocked: number;
  blockedPercentage: number;
};

const TrainingStats = () => {
  const { t } = useTranslation(['training']);
  const [trainingEventsStats, setTrainingEventsStats] = useState<TrainingEventsStats>({
    total: 0,
    scheduled: 0,
    scheduledPercentage: 0,
    completed: 0,
    completedPercent: 0,
    inSession: 0,
    inSessionProgress: 0,
    blocked: 0,
    blockedPercentage: 0,
  });

  const [eventsStatusChartData, setEventsStatusChartData] = useState<ChartDataSet | null>({
    hasData: false,
    heading: '',
    labels: [],
    datasets: [],
  });

  const [eventPassRateChartData, setEventPassRateChartData] = useState<ChartDataSet | null>({
    hasData: false,
    heading: '',
    labels: [],
    datasets: [],
  });

  const setFakeData = useCallback(() => {
    setTrainingEventsStats({
      total: 25,
      scheduled: 5,
      scheduledPercentage: 0,
      completed: 10,
      completedPercent: 0,
      inSession: 5,
      inSessionProgress: 0,
      blocked: 5,
      blockedPercentage: 0,
    });

    setEventsStatusChartData({
      hasData: true,
      heading: '',
      labels: ['Scheduled', 'In Session', 'Blocked', 'Completed'],
      datasets: [{ label: 'Total', data: [5, 5, 5, 10] }],
    });

    setEventPassRateChartData({
      hasData: true,
      heading: '',
      labels: ['Passed', 'Failed'],
      datasets: [{ label: 'Participants', data: [60, 20], backgroundColor: ['#008584', '#f04040'] }],
    });
  }, []);

  return (
    <div className="grid w-full grid-cols-4 gap-2">
      <div className="col-span-1 row-span-1">
        <StatsBlock
          translationNamespace="training"
          headingKey={'training.stats.scheduled.label'}
          stat={trainingEventsStats.total}
          change={0}
          changeMode={ChangeMode.NOT_APPLICABLE}
        />
      </div>
      <div className="col-span-1 row-span-1">
        <StatsBlock
          translationNamespace="training"
          headingKey={`training.stats.in-session.label`}
          stat={trainingEventsStats.inSession}
          change={0}
          changeMode={ChangeMode.NOT_APPLICABLE}
        />
      </div>
      <div className="col-span-1 row-span-1">
        <StatsBlock
          translationNamespace="training"
          headingKey={`training.stats.blocked.label`}
          stat={trainingEventsStats.blocked}
          change={0}
          changeMode={ChangeMode.NOT_APPLICABLE}
        ></StatsBlock>
      </div>
      <div className="col-span-1 row-span-1">
        <StatsBlock
          translationNamespace="training"
          headingKey={`training.stats.completed.label`}
          stat={trainingEventsStats.completed}
          change={0}
          changeMode={ChangeMode.NOT_APPLICABLE}
        />
      </div>
      <div className="h-dashboard-row-3 4k:h-dashboard-row-4 col-span-2 row-span-1">
        <DashboardBlock>
          <DashboardBlock.Header>
            <div className="relative flex justify-between">
              <Tooltip text={t('training.stats.event-status-chart.heading')}>
                {(tooltip) => (
                  <div className="truncate" {...tooltip}>
                    {t('training.stats.event-status-chart.heading')}
                  </div>
                )}
              </Tooltip>
              <div className="flex items-center gap-2">
                {/* <ChartDataSourceSelector
                  mode={tPrefix}
                  onChange={(type, value, text) => switchDataSource(type, value, text, updateTotalAssetChart)}
                  selectedSource={totalAssetsSelectedSource}
                /> */}
                <FunnelIcon className="h-4 w-4" onClick={setFakeData} />
              </div>
            </div>
          </DashboardBlock.Header>
          <DashboardBlock.Body>
            {eventsStatusChartData && eventsStatusChartData.hasData && (
              <DoughnutChart
                dataset={eventsStatusChartData.datasets[0].data}
                dataLabels={eventsStatusChartData.labels}
                disabled={trainingEventsStats.total === 0}
              >
                <div className="text-dpm-48">{trainingEventsStats.total}</div>
              </DoughnutChart>
            )}
            {eventsStatusChartData && !eventsStatusChartData.hasData && (
              <NoDataPlaceholder>{t('training.stats.event-status-chart.no-data')}</NoDataPlaceholder>
            )}
          </DashboardBlock.Body>
        </DashboardBlock>
      </div>
      <div className="h-dashboard-row-3 4k:h-dashboard-row-4 col-span-2 row-span-1">
        <DashboardBlock>
          <DashboardBlock.Header>
            <div className="relative flex justify-between">
              <Tooltip text={t('training.stats.event-pass-rate-chart.heading')}>
                {(tooltip) => (
                  <div className="truncate" {...tooltip}>
                    {t('training.stats.event-pass-rate-chart.heading')}
                  </div>
                )}
              </Tooltip>

              <div className="flex items-center gap-2">
                {/* <ChartDataSourceSelector
                  mode={tPrefix}
                  onChange={(type, value, text) => switchDataSource(type, value, text, updateTotalAssetChart)}
                  selectedSource={totalAssetsSelectedSource}
                /> */}
                <FunnelIcon className="h-4 w-4" onClick={setFakeData} />
              </div>
            </div>
          </DashboardBlock.Header>
          <DashboardBlock.Body>
            {eventPassRateChartData && eventPassRateChartData.hasData && (
              <BarChart filterZeros={false} data={eventPassRateChartData} limit={8} stacked={false} type={BarChartType.VERTICAL} />
            )}
            {eventPassRateChartData && !eventPassRateChartData.hasData && (
              <NoDataPlaceholder>{t('training.stats.event-pass-rate-chart.no-data')}</NoDataPlaceholder>
            )}
          </DashboardBlock.Body>
        </DashboardBlock>
      </div>
    </div>
  );
};

export default TrainingStats;
