import { createContext, useContext } from 'react';
import { Training } from '../../models/Training';
import { FCWithChildren } from '../../types/FCWithChildren';

type TrainingContextType = {
  training: Training;
};

export const TrainingContext = createContext<TrainingContextType>(null!);
export const useTraining = (): TrainingContextType => useContext(TrainingContext);

export const TrainingProvider: FCWithChildren<TrainingContextType> = ({ training: value, children }) => (
  <TrainingContext.Provider value={{ training: value }}>{children}</TrainingContext.Provider>
);
