import SearchIcon from '../../components/shared/icon/SearchIcon';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import Tooltip from '../../components/shared/Tooltip';
import { ComponentRef, useMemo, useRef, useState } from 'react';
import { iconButtonClasses, iconButtonContainerClasses, iconButtonDisabledClasses } from '../../components/shared/form-control/Button';
import DownloadIcon from '../../components/shared/icon/DownloadIcon';
import { Input, InputStyle } from '../../components/shared/form-control/Input';
import { useTranslation } from 'react-i18next';
import { SortingState, ColumnFiltersState } from '@tanstack/react-table';
import FunnelIcon from '../../components/shared/icon/FunnelIcon';
import SortIcon from '../../components/shared/icon/SortIcon';
import Popover from '../../components/shared/popover/Popover';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import EmptyTraineesSvg from '../../assets/images/empty-trainees.svg';
import { ChangeMode, StatsBlock } from '../../components/client-dashboard/StatsBlock';
import { useTraining } from '../../contexts/training/TrainingContext';

const Trainees = () => {
  const { t } = useTranslation(['training', 'table-view']);
  const { training } = useTraining();
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const totalFilters = useMemo(() => columnFilters.length, [columnFilters]);
  const totalSorts = useMemo(() => sorting.length, [sorting]);
  const filterColumnPopoverRef = useRef<Record<string, ComponentRef<typeof Popover>>>({});
  const disabled = !training.id;
  return (
    <>
      <div className="flex w-full items-center justify-between">
        <Heading size={HeadingSize.H5}>Trainees</Heading>
        <div className={`flex gap-2 ${disabled ? iconButtonDisabledClasses : ''}`}>
          <div className="flex items-center">
            <div
              className={`overflow-hidden transition-all duration-300 ease-in-out ${isSearchExpanded ? 'mr-2 w-64 px-1 opacity-100' : 'mr-0 w-0 px-0 opacity-0'} `}
            >
              <Input
                style={InputStyle.MINIMAL}
                placeholder={t('trainees.search')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClear={() => setSearchTerm('')}
                autoFocus={isSearchExpanded}
              />
            </div>
            <Tooltip text={t('trainees.tooltips.search')}>
              {(tooltip) => (
                <div {...tooltip}>
                  {
                    <SearchIcon
                      className={`${iconButtonClasses} ${searchTerm.length > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
                      onClick={() => setIsSearchExpanded((prev) => !prev)}
                    />
                  }
                </div>
              )}
            </Tooltip>
          </div>
          <Tooltip text={t('trainees.tooltips.download')}>
            {(tooltip) => (
              <div {...tooltip}>{<DownloadIcon className={`${iconButtonClasses}`} onClick={() => setIsSearchExpanded((prev) => !prev)} />}</div>
            )}
          </Tooltip>
          <Popover ref={(ref) => (filterColumnPopoverRef.current['filtering'] = ref!)} content={<></>} placement="bottom-end">
            {(popover, toggle) => (
              <div
                {...popover}
                className={`${iconButtonContainerClasses} ${totalFilters > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
                {...mouseAndKeyboardCallbackProps(toggle)}
              >
                <Tooltip text={t('trainees.tooltips.filter')}>{(tooltip) => <div {...tooltip}>{<FunnelIcon className="h-6 w-5" />}</div>}</Tooltip>
                {totalFilters > 0 && <div className="px-2 font-medium">{t('table-view:filters.count', { count: totalFilters })}</div>}
              </div>
            )}
          </Popover>

          <Popover content={<></>} placement="bottom-end">
            {(popover, toggle) => (
              <div
                {...popover}
                className={`${iconButtonContainerClasses} ${totalSorts > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
                {...mouseAndKeyboardCallbackProps(toggle)}
              >
                <Tooltip text={t('trainees.tooltips.sorting')}>{(tooltip) => <div {...tooltip}>{<SortIcon className="h-6 w-5" />}</div>}</Tooltip>
                {totalSorts > 0 && <div className="px-2 font-medium">{t('table-view:sorting.count', { count: totalSorts })}</div>}
              </div>
            )}
          </Popover>
        </div>
      </div>
      <div className="mt-2 flex items-center justify-between gap-4">
        <StatsBlock
          headingKey={'trainees.stats.completed'}
          statsKey="training.stats.percentage-symbol"
          stat={'0'}
          translationNamespace={'training'}
          change={0}
          changeMode={ChangeMode.NOT_APPLICABLE}
        />
        <StatsBlock
          headingKey={'trainees.stats.overdue'}
          statsKey="training.stats.percentage-symbol"
          stat={'0'}
          translationNamespace={'training'}
          change={0}
          changeMode={ChangeMode.NOT_APPLICABLE}
        />
        <StatsBlock
          headingKey={'trainees.stats.non-compliant'}
          statsKey="training.stats.percentage-symbol"
          stat={'0'}
          translationNamespace={'training'}
          change={0}
          changeMode={ChangeMode.NOT_APPLICABLE}
        />
      </div>
      <div className="border-gray-5 my-2 flex min-h-80 items-center rounded-[5px] border-[1px] bg-white p-4">
        <div className="flex w-full flex-col items-center justify-center gap-6 text-center">
          <img src={EmptyTraineesSvg} alt={t('events.empty')} className="max-w-[145px]" />
          <div>
            <div className="text-gray-2">{t('events.empty')}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trainees;
