import React, { useMemo } from 'react';
import DropdownSelect, { DropdownSize } from './DropdownSelect';

interface TimePickerProps {
  value?: string;
  onChange: (time: string) => void;
  disabled?: boolean;
}

const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let min = 0; min < 60; min += 15) {
      const formatted = `${String(hour).padStart(2, '0')}:${String(min).padStart(2, '0')}`;
      times.push({ id: formatted, text: formatted, value: formatted });
    }
  }
  return times;
};

const TimePicker: React.FC<TimePickerProps> = ({ value, onChange, disabled }) => {
  const timeOptions = generateTimeOptions();

  const handleChange = (selectedOption: { value: string } | null) => {
    if (selectedOption) {
      onChange(selectedOption.value);
    }
  };

  const selectedTime = useMemo(() => timeOptions.find((x) => x.value === value) || timeOptions[0], [timeOptions, value]);

  return (
    <div className="relative w-40">
      <DropdownSelect
        onChange={handleChange}
        allowCreation={false}
        options={timeOptions}
        value={selectedTime}
        size={DropdownSize.S}
        disabled={disabled}
      />
    </div>
  );
};

export default TimePicker;
