import { Translations } from './Translation';

export type Training = {
  id: string;
  title: string;
  translations: Translations;
  status: TrainingStatus;
  statusUtc: string;
  useUniqueIdentifier: boolean;
  createdUtc: string;
  createdByName: string;
};

export type TrainingCreate = {
  clientId: string;
  title: string;
  translations: Translations;
  useUniqueIdentifier: boolean;
  originId?: string;
};

export enum TrainingStatus {
  Draft = 0,
  Active = 1,
}
