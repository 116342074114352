import { FC, useCallback, useState } from 'react';
import { ModalContext } from '../../contexts/ModalContext';
import { useTranslation } from 'react-i18next';
import StandardModal from '../../components/shared/modal/variants/StandardModal';
import { useTraining } from '../../contexts/training/TrainingContext';
import { Input, InputStyle } from '../../components/shared/form-control/Input';
import MultiTextField from '../../components/shared/form-control/MultiTextField';
import PropertyLayout from '../../components/form-builder/PropertyLayout';
import Button, { ButtonSize, ButtonType } from '../../components/shared/form-control/Button';
import RadioButton from '../../components/shared/form-control/RadioButton';
import { DatePicker, DatePickerType } from '../../components/shared/form-control/DatePicker';
import DateUtils from '../../utils/DateUtils';
import TimePicker from '../../components/shared/form-control/TimePicker';
import { DateInterval, DateIntervalKeys } from '../../models/DateInterval';
import DropdownSelect, { DropdownSize } from '../../components/shared/form-control/DropdownSelect';
import { Option } from '../../components/Option';

type EventModalProps = {
  open: boolean;
  onClose: () => void;
};

const EventModal: FC<EventModalProps> = (props) => {
  const { open, onClose } = props;
  const { t } = useTranslation(['training', 'common']);
  const { training } = useTraining();
  const [eventTitle, setEventTitle] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [isFixedSchedule, setIsFixedSchedule] = useState(false);
  const [isPeriodSchedule, setIsPeriodSchedule] = useState(false);
  const [noScedule, setNoSchedule] = useState(true);
  const [selectedTime, setSelectedTime] = useState<string | undefined>();
  const intervals = DateUtils.dateIntervalOptions(t);
  const [periodAmount, setPeriodAmount] = useState(0);
  const [selectedInitialPerionInterval, setSelectedInitialPerionInterval] = useState<Option<string, DateInterval>>({
    id: DateInterval.DAY.toString(),
    value: DateInterval.MONTH,
    text: t(DateIntervalKeys[DateInterval.MONTH]),
  });

  const [useNotice, setUseNotice] = useState(false);
  const [noNotice, setNoNotice] = useState(true);
  const [noticeAmount, setNoticeAmount] = useState(0);
  const [selectedInitialNoticeInterval, setSelectedInitialNoticeInterval] = useState<Option<string, DateInterval>>({
    id: DateInterval.DAY.toString(),
    value: DateInterval.MONTH,
    text: t(DateIntervalKeys[DateInterval.MONTH]),
  });

  const [useMultipleRecurrance, setUseMultipleRecurrance] = useState(false);
  const [noRecurrance, setNoRecurrance] = useState(true);
  const [recurranceAmount, setRecurranceAmount] = useState(0);
  const [selectedInitialRecurranceInterval, setSelectedInitialRecurranceInterval] = useState<Option<string, DateInterval>>({
    id: DateInterval.DAY.toString(),
    value: DateInterval.MONTH,
    text: t(DateIntervalKeys[DateInterval.MONTH]),
  });

  const onIsFixedScheduleChange = useCallback((value: boolean) => {
    if (value) {
      setIsFixedSchedule(value);
      setIsPeriodSchedule(!value);
      setNoSchedule(!value);
    }
  }, []);
  const onIsPeriodScheduleChange = useCallback((value: boolean) => {
    if (value) {
      setIsFixedSchedule(!value);
      setIsPeriodSchedule(value);
      setNoSchedule(!value);
    }
  }, []);
  const onNoScheduleChange = useCallback((value: boolean) => {
    if (value) {
      setIsFixedSchedule(!value);
      setIsPeriodSchedule(!value);
      setNoSchedule(value);
    }
  }, []);
  const onUseNoticeChange = useCallback((value: boolean) => {
    if (value) {
      setUseNotice(value);
      setNoNotice(!value);
    }
  }, []);
  const onNoNoticeChange = useCallback((value: boolean) => {
    if (value) {
      setUseNotice(!value);
      setNoNotice(value);
    }
  }, []);
  const onMultipleRecurranceChange = useCallback((value: boolean) => {
    if (value) {
      setUseMultipleRecurrance(value);
      setNoRecurrance(!value);
    }
  }, []);
  const onNoRecurranceChange = useCallback((value: boolean) => {
    if (value) {
      setUseMultipleRecurrance(!value);
      setNoRecurrance(value);
    }
  }, []);
  const onSave = useCallback(() => {}, []);
  const onCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <ModalContext.Provider value={{ open: open, onClose: onClose, modalWidth: 'w-2/5' }}>
      <StandardModal title={t('event-modal.heading')} onCancelClick={onCancel} onConfirmClick={onSave}>
        <Input
          data-cy="event-name"
          value={eventTitle}
          onChange={(e) => setEventTitle(e.target.value)}
          placeholder={t('event-modal.fields.name.label')}
          label={t('event-modal.fields.name.placeholder')}
          maxLength={50}
          required={true}
          error={''}
          errorState={false}
        />
        <MultiTextField
          data-cy="event-description"
          value={eventDescription}
          onChange={(e) => setEventTitle(e.target.value)}
          placeholder={t('event-modal.fields.description.label')}
          label={t('event-modal.fields.description.placeholder')}
          maxLength={250}
        />
        {/* Assigness */}
        <PropertyLayout
          label={t('event-modal.fields.assignees.heading')}
          description={t('event-modal.fields.assignees.description')}
        ></PropertyLayout>

        {/* MAterial */}
        <PropertyLayout label={t('event-modal.fields.material.heading')} description={t('event-modal.fields.material.description')}>
          <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={() => {}}>
            {t('event-modal.fields.material.button')}
          </Button>
        </PropertyLayout>

        {/* Quix */}
        <PropertyLayout label={t('event-modal.fields.quiz.heading')} description={t('event-modal.fields.quiz.description')}>
          <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={() => {}}>
            {t('event-modal.fields.quiz.button')}
          </Button>
        </PropertyLayout>

        {/* Schedule */}
        <PropertyLayout label={t('event-modal.fields.schedule.heading')} description={t('event-modal.fields.schedule.description')}>
          <div className="ml-2 flex flex-col gap-2">
            <div>
              <RadioButton
                size={'small'}
                value={isFixedSchedule}
                label={t('event-modal.fields.schedule.fixed.heading')}
                description={t('training:event-modal.fields.schedule.fixed.description')}
                onChange={onIsFixedScheduleChange}
              />

              <div className="ml-7 flex w-full items-center justify-between">
                <div>
                  <span className="text-dpm-14">{t('event-modal.fields.schedule.fixed.start.label')}</span>
                  <div className="flex items-center gap-2">
                    <DatePicker
                      disabled={!isFixedSchedule}
                      date={null}
                      onChange={() => {}}
                      type={DatePickerType.INPUT}
                      inputSize={InputStyle.MINIMAL}
                    />
                    <TimePicker value={selectedTime} onChange={setSelectedTime} disabled={!isFixedSchedule} />
                  </div>
                </div>
                <div>
                  <span className="text-dpm-14">{t('event-modal.fields.schedule.fixed.end.label')}</span>
                  <div className="flex items-center gap-2">
                    <DatePicker
                      disabled={!isFixedSchedule}
                      date={null}
                      onChange={() => {}}
                      type={DatePickerType.INPUT}
                      inputSize={InputStyle.MINIMAL}
                    />
                    <TimePicker value={selectedTime} onChange={setSelectedTime} disabled={!isFixedSchedule} />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <RadioButton
                size={'small'}
                value={isPeriodSchedule}
                label={t('event-modal.fields.schedule.period.heading')}
                description={t('training:event-modal.fields.schedule.period.description')}
                onChange={onIsPeriodScheduleChange}
              />

              <div className="ml-7 flex w-full items-center justify-between">
                <div className="flex w-[200px] justify-start gap-4">
                  <div className="w-14 flex-shrink-0">
                    <Input
                      disabled={!isPeriodSchedule}
                      style={InputStyle.MINIMAL}
                      type="number"
                      className="text-center"
                      value={`${periodAmount}`}
                      min={0}
                      onChange={(event) => {
                        setPeriodAmount(parseInt(event.target.value));
                      }}
                    />
                  </div>
                  <div className="flex-grow">
                    <DropdownSelect
                      disabled={!isPeriodSchedule}
                      className="w-40 text-left"
                      value={selectedInitialPerionInterval}
                      options={intervals}
                      size={DropdownSize.S}
                      onChange={(option) => {
                        setSelectedInitialPerionInterval(option);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <RadioButton size={'small'} value={noScedule} label={t('event-modal.fields.schedule.none.heading')} onChange={onNoScheduleChange} />
            </div>
          </div>
        </PropertyLayout>

        {/* Notice */}
        <PropertyLayout label={t('event-modal.fields.advanced-notice.heading')} description={t('event-modal.fields.advanced-notice.description')}>
          <div className="ml-2 flex flex-col gap-2">
            <div>
              <RadioButton size={'small'} value={noNotice} label={t('event-modal.fields.advanced-notice.none.heading')} onChange={onNoNoticeChange} />
            </div>
            <div>
              <RadioButton
                size={'small'}
                value={useNotice}
                label={t('event-modal.fields.advanced-notice.use-notice.heading')}
                onChange={onUseNoticeChange}
              />

              <div className="ml-7 flex w-full items-center justify-between">
                <div className="flex w-[200px] justify-start gap-4">
                  <div className="w-14 flex-shrink-0">
                    <Input
                      disabled={!useNotice}
                      style={InputStyle.MINIMAL}
                      type="number"
                      className="text-center"
                      value={`${noticeAmount}`}
                      min={0}
                      onChange={(event) => {
                        setNoticeAmount(parseInt(event.target.value));
                      }}
                    />
                  </div>
                  <div className="flex-grow">
                    <DropdownSelect
                      disabled={!useNotice}
                      className="w-40 text-left"
                      value={selectedInitialNoticeInterval}
                      options={intervals}
                      size={DropdownSize.S}
                      onChange={(option) => {
                        setSelectedInitialNoticeInterval(option);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PropertyLayout>

        {/* Recurring */}
        <PropertyLayout label={t('event-modal.fields.recurring.heading')} description={''}>
          <div className="ml-2 flex flex-col gap-2">
            <div>
              <RadioButton
                size={'small'}
                value={noRecurrance}
                label={t('event-modal.fields.recurring.once.heading')}
                onChange={onNoRecurranceChange}
              />
            </div>
            <div>
              <RadioButton
                size={'small'}
                value={useMultipleRecurrance}
                label={t('event-modal.fields.recurring.multiple.heading')}
                onChange={onMultipleRecurranceChange}
              />

              <div className="ml-7 flex w-full items-center justify-between">
                <div className="flex w-[200px] justify-start gap-4">
                  <div className="w-14 flex-shrink-0">
                    <Input
                      disabled={!useMultipleRecurrance}
                      style={InputStyle.MINIMAL}
                      type="number"
                      className="text-center"
                      value={`${recurranceAmount}`}
                      min={0}
                      onChange={(event) => {
                        setRecurranceAmount(parseInt(event.target.value));
                      }}
                    />
                  </div>
                  <div className="flex-grow">
                    <DropdownSelect
                      disabled={!useMultipleRecurrance}
                      className="w-40 text-left"
                      value={selectedInitialRecurranceInterval}
                      options={intervals}
                      size={DropdownSize.S}
                      onChange={(option) => {
                        setSelectedInitialRecurranceInterval(option);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PropertyLayout>
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default EventModal;
