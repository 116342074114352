import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronIcon, ChevronType } from '../../components/shared/icon/ChevronIcon';
import Accordion from '../../components/shared/accordion/Accordion';
import { Input, InputStyle } from '../../components/shared/form-control/Input';
import Checkbox, { SliderSize } from '../../components/shared/form-control/Checkbox';
import { ClientFormTag, ClientFormUser } from '../../models/ClientFormUser';
import UsersSelect from '../../components/ownership/UsersSelect';
import useFetchClientUsers from '../../hooks/useFetchClientUsers';
import UserListRenderer from '../../components/ownership/UserListRenderer';
import { ImageSize, ProfileImageStack } from '../../components/ownership/ProfileImageStack';
import { PeopleType } from '../../models/Distribution';
import XIcon from '../../components/shared/icon/XIcon';
import { useTranslation } from 'react-i18next';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import Button, { ButtonSize, ButtonType } from '../../components/shared/form-control/Button';
import Trainees from './Trainees';
import Events from './Events';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import StaticBreadCrumb from '../../components/shared/breadcumb/StaticBreadCrumb';
import TrainingService from '../../services/TrainingService';
import { useCurrentClient } from '../../global-state/Clients';
import TranslatableInput from '../../components/shared/form-control/TranslatableInput';
import { useParams } from 'react-router-dom';
import { Training, TrainingStatus } from '../../models/Training';
import { TrainingProvider } from '../../contexts/training/TrainingContext';
import PageLoader from '../../components/shared/page-loader/PageLoader';
import InfoIcon from '../../components/shared/icon/InfoIcon';
import InlineEditor from '../../components/shared/form-control/InlineEditor';
import PlusIcon from '../../components/shared/icon/PlusIcon';
import DateUtils from '../../utils/DateUtils';
import TrainingStats from './TrainingStats';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import User from '../../models/User';
import { useFetchActiveClientGroups } from '../../hooks/useFetchActiveClientGroups';
import { Groups } from '../../components/ownership/ClientFormRoleGroup';
import { deDuplicate } from '../../utils/ListUtils';
import EventModal from './EventModal';

const TrainingPage = () => {
  const { t } = useTranslation(['training', 'common']);
  const client = useCurrentClient((x) => x.value);
  const { trainingId } = useParams<{ trainingId: string }>();
  const [training, setTraining] = useState<Training>();
  const [isDirty, setIsDirty] = useState(false);
  const leftPaneRef = useRef<HTMLDivElement>(null);
  const [leftNavCollapsed, setLeftNavCollapsed] = useState(false);
  const { data: clientUsers = [] } = useFetchClientUsers();
  const [assignedTrainer, setAssignedTrainer] = useState<User | null>(null);
  const [assignedTrainees, setAssignedTrainees] = useState<User[]>([]);
  const [assignedUserTags, setAssignedUserTags] = useState<ClientFormTag[]>([]);
  const [showNewEventModal, setShowNewEventModal] = useState(false);
  const [activeAccordions, setActiveAccordions] = useState<Record<string, boolean>>({
    ['Details']: true,
    ['Trainer']: true,
    ['Trainees']: true,
    ['Material']: true,
    ['Quiz']: true,
  });
  const toasts = useToasts();
  const { data: clientGroups = [] } = useFetchActiveClientGroups();

  const breadCrumbs = useMemo(() => {
    return []; // TODO: Add breadcrumb items
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateTrainingField = useCallback((field: keyof Training, value: any) => {
    setTraining((prev) => (prev ? { ...prev, [field]: value } : prev));
    setIsDirty(true);
  }, []);

  useEffect(() => {
    if (!client?.id) return;
    if (trainingId) {
      TrainingService.getTraining(trainingId).then((response) => {
        setTraining(response.data);
        // setAssignedTrainer(response.data.trainer);
        // setAssignedTrainees(response.data.trainees);
      });
    } else {
      setTraining({
        id: '',
        title: t('untitled'),
        translations: {},
        useUniqueIdentifier: false,
        status: TrainingStatus.Draft,
        statusUtc: new Date().toISOString(),
        createdUtc: new Date().toISOString(),
        createdByName: '',
      });
    }
  }, [client?.id, t, trainingId]);

  const onSave = useCallback(() => {
    if (!client?.id || !training) return;
    if (!training.id) {
      TrainingService.createTraining({
        clientId: client?.id,
        title: training.title,
        translations: training.translations,
        useUniqueIdentifier: training.useUniqueIdentifier,
      }).then((response) => {
        setTraining(response.data);
        toasts.addToast({
          title: t('training.toasts.publish.success.title'),
          description: t('training.toasts.publish.success.description'),
          type: ToastType.SUCCESS,
          expiresInMs: 5000,
        });
        window.history.pushState(null, '', `/clients/${client.id}/training/${response.data.id}`);
      });
    }
  }, [client, t, toasts, training]);

  useEffect(() => {
    if (!training?.id || !client?.id || !isDirty) return;

    const handler = setTimeout(() => {
      TrainingService.updateTraining(training.id, {
        clientId: client.id,
        title: training.title,
        translations: training.translations,
        useUniqueIdentifier: training.useUniqueIdentifier,
      }).then(() => setIsDirty(false));
    }, 1000); // Debounce updates (1s delay)

    return () => clearTimeout(handler);
  }, [training, client?.id, isDirty]);

  const onNewEvent = useCallback(() => {
    setShowNewEventModal(true);
  }, []);

  const onUserRemoveInternal = useCallback(
    (user: { id: string }) => {
      // If a user is removed, that group cannot be synced anymore
      let changed = false;
      const groups = assignedUserTags.map((x) => {
        // If the removed user is part of the group
        if (!x.users?.some((y) => y.id === user.id)) return x;

        if (x.synchronized) {
          changed = true;
          x.synchronized = false;
        }

        return x;
      });

      if (changed) {
        setAssignedUserTags(groups);
      }
      setAssignedTrainees((prev) => prev.filter((x) => x.id !== user.id));
    },
    [assignedUserTags],
  );

  const onTagsChangeInternal = useCallback(
    (tags: ClientFormTag[]) => {
      // If a group is syned, then _all_ users within the group must be present
      const syncedGroups = tags.filter((x) => x.synchronized);
      const syncedGroupUsers = deDuplicate(
        syncedGroups.flatMap((x) => x.users ?? []),
        'id',
      );

      const missingUsers = syncedGroupUsers.filter((x) => !assignedTrainees.some((y) => y.id === x.id));

      if (missingUsers.length > 0) {
        setAssignedTrainees(assignedTrainees.concat(missingUsers));
      }

      setAssignedUserTags(tags);
    },
    [assignedTrainees],
  );

  return (
    <PageLoader loading={!training}>
      {training && (
        <TrainingProvider training={training}>
          <div className="flex h-full min-h-full flex-col">
            <TopNavPortal>{<StaticBreadCrumb breadCrumbs={breadCrumbs} currentStepName={training?.title || ''} />}</TopNavPortal>
            <div className="flex h-full flex-col">
              <div className="relative flex flex-1 overflow-y-auto overflow-x-hidden pr-1">
                <div>
                  <div className="relative h-full">
                    <div
                      ref={leftPaneRef}
                      className={`sticky top-0 h-full w-[300px] overflow-y-auto border-r pb-4 transition-[margin] duration-300 ${
                        leftNavCollapsed ? '-ml-[285px]' : 'mr-0'
                      }`}
                    >
                      {/* <LeftNavHeading title="Setup Training" /> */}
                      <Accordion
                        title={t('training.sidebar.details')}
                        active={activeAccordions['Details']}
                        onChange={(active) => setActiveAccordions((prev) => ({ ...prev, ['Details']: active }))}
                      >
                        {!training.id && (
                          <Input
                            label={t('training.details.title.label')}
                            placeholder={t('training.details.title.label')}
                            style={InputStyle.MINIMAL}
                            value={training?.title}
                            onChange={(e) => updateTrainingField('title', e.target.value)}
                          />
                        )}
                        <TranslatableInput
                          label={t('training.details.prefix.label')}
                          placeholder={t('training.details.prefix.label')}
                          translationKey="prefix"
                          translations={training?.translations || {}}
                          onTranslationsChange={(translations) => updateTrainingField('translations', translations)}
                          style={InputStyle.MINIMAL}
                        />
                        <Checkbox
                          label={t('training.details.unique-code.label')}
                          containerClassName="mt-4"
                          labelClass="text-dpm-14"
                          slider
                          sliderSize={SliderSize.S}
                          labelBeforeCheckbox
                          value={training.useUniqueIdentifier}
                          onChange={(value) => updateTrainingField('useUniqueIdentifier', value)}
                        />
                      </Accordion>
                      <Accordion
                        title={
                          <div className="flex w-full items-center justify-between">
                            <div>{t('training.sidebar.trainer')}</div>
                            <div className="font-normal">
                              {!!assignedTrainer && activeAccordions['Trainer'] && (
                                <div className="animate-fade-in-scale">
                                  <UsersSelect
                                    users={clientUsers}
                                    selectedUsers={assignedTrainer ? [assignedTrainer] : []}
                                    formUsers={[]}
                                    selectedTags={[]}
                                    singleSelect
                                    onUsersChanged={function (users): void {
                                      setAssignedTrainer(users[0]);
                                    }}
                                    clientGroups={clientGroups}
                                  />
                                </div>
                              )}
                              {!!assignedTrainer && !activeAccordions['Trainer'] && (
                                <div className="animate-fade-in-scale">
                                  <ProfileImageStack users={[assignedTrainer]} displayLimit={3} size={ImageSize.XS} />
                                </div>
                              )}
                            </div>
                          </div>
                        }
                        active={activeAccordions['Trainer']}
                        onChange={(active) => setActiveAccordions({ ...activeAccordions, ['Trainer']: active })}
                      >
                        <div className="flex items-center gap-2">
                          {!assignedTrainer && (
                            <>
                              <UsersSelect
                                users={clientUsers}
                                selectedUsers={assignedTrainer ? [assignedTrainer] : []}
                                formUsers={[]}
                                selectedTags={[]}
                                singleSelect
                                onUsersChanged={function (users): void {
                                  setAssignedTrainer(users[0]);
                                }}
                                clientGroups={clientGroups}
                              />
                              <div className="text-dpm-14">{t('unassigned')}</div>
                            </>
                          )}
                          {!!assignedTrainer && (
                            <UserListRenderer
                              id={assignedTrainer.id}
                              text={assignedTrainer.fullName || ''}
                              userImageId={assignedTrainer.userImageId}
                              value={PeopleType.Member}
                              size={ImageSize.XS}
                            />
                          )}
                        </div>
                      </Accordion>
                      <Accordion
                        title={
                          <div className="flex w-full items-center justify-between">
                            <div>{t('training.sidebar.trainees')}</div>
                            <div className="font-normal">
                              {assignedTrainees.length > 0 && activeAccordions['Trainees'] && (
                                <div className="animate-fade-in-scale">
                                  <UsersSelect
                                    users={clientUsers}
                                    selectedUsers={assignedTrainees}
                                    selectedTags={assignedUserTags}
                                    formUsers={[]}
                                    onUsersChanged={function (users, tags): void {
                                      setAssignedTrainees(users);
                                      setAssignedUserTags(tags);
                                    }}
                                    clientGroups={clientGroups}
                                  />
                                </div>
                              )}
                              {assignedTrainees.length > 0 && !activeAccordions['Trainees'] && (
                                <div className="animate-fade-in-scale">
                                  <ProfileImageStack users={assignedTrainees} displayLimit={3} size={ImageSize.XS} />
                                </div>
                              )}
                            </div>
                          </div>
                        }
                        active={activeAccordions['Trainees']}
                        onChange={(active) => setActiveAccordions({ ...activeAccordions, ['Trainees']: active })}
                      >
                        <>
                          <div className="flex items-center gap-2 pb-2">
                            {!assignedTrainees.length && (
                              <>
                                <UsersSelect
                                  users={clientUsers}
                                  selectedUsers={assignedTrainees}
                                  selectedTags={assignedUserTags}
                                  formUsers={[]}
                                  onUsersChanged={function (users, tags): void {
                                    setAssignedTrainees(users);
                                    setAssignedUserTags(tags);
                                  }}
                                  clientGroups={clientGroups}
                                />
                                <div className="text-dpm-14">{t('unassigned')}</div>
                              </>
                            )}
                            {assignedTrainees.length > 0 && (
                              <div className="flex w-full flex-col">
                                {assignedTrainees.map((trainee) => (
                                  <div
                                    key={trainee.id}
                                    className="hover:bg-gray-6 group my-1 flex w-full items-center justify-between rounded-[5px] transition-colors"
                                  >
                                    <UserListRenderer
                                      key={trainee.id}
                                      id={trainee.id}
                                      text={trainee.fullName || ''}
                                      userImageId={trainee.userImageId}
                                      value={PeopleType.Member}
                                      size={ImageSize.XS}
                                    />
                                    <XIcon
                                      className="mr-2 h-5 w-5 flex-shrink-0 text-black opacity-0 transition-opacity group-hover:opacity-100"
                                      onClick={() => {
                                        onUserRemoveInternal(trainee);
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                          <Groups
                            searchPhrase=""
                            tags={assignedUserTags}
                            users={assignedTrainees}
                            onChange={onTagsChangeInternal}
                            onGroupRemoved={() => ({
                              /* TODO */
                            })}
                          />
                        </>
                      </Accordion>
                      <Accordion
                        title={t('training.sidebar.material')}
                        active={activeAccordions['Material']}
                        onChange={(active) => setActiveAccordions({ ...activeAccordions, ['Material']: active })}
                      >
                        <span className="text-dpm-14">{t('material.empty')}</span>
                      </Accordion>
                      <Accordion
                        title={t('training.sidebar.quiz')}
                        active={activeAccordions['Quiz']}
                        onChange={(active) => setActiveAccordions({ ...activeAccordions, ['Quiz']: active })}
                      >
                        <span className="text-dpm-14">{t('quiz.empty')}</span>
                      </Accordion>
                    </div>
                    <ChevronIcon
                      onClick={() => setLeftNavCollapsed((prev) => !prev)}
                      type={leftNavCollapsed ? ChevronType.RIGHT : ChevronType.LEFT}
                      className={`hover:bg-gray-5 absolute -right-3 top-4 z-[40] h-6 w-6 rounded-full border bg-white transition duration-500 ease-in-out`}
                    />
                  </div>
                </div>
                <div className={`bg-background-1 flex w-full flex-col overflow-y-auto px-6 py-2`}>
                  <div className="mt-1 py-2">
                    <div className="flex w-full items-center justify-between">
                      <div className="w-full">
                        <InlineEditor size="text-dpm-32" value={training?.title} onChange={(value) => updateTrainingField('title', value)} />
                        {training.id && (
                          <span className="text-dpm-14">
                            {t('training.published-on', { date: DateUtils.formatDateTime(new Date(training.createdUtc)) })}
                          </span>
                        )}
                      </div>
                      <div className="flex gap-2">
                        {!training.id && (
                          <>
                            <Button type={ButtonType.SECONDARY} size={ButtonSize.S} onClick={() => history.back()}>
                              {t('training.buttons.cancel')}
                            </Button>
                            <Button type={ButtonType.PRIMARY} size={ButtonSize.S} onClick={onSave}>
                              {t('training.buttons.save')}
                            </Button>
                          </>
                        )}
                        {training.id && (
                          <Button type={ButtonType.PRIMARY} size={ButtonSize.S} onClick={onNewEvent}>
                            <Button.Slot name="Icon">
                              <PlusIcon className="h-3 w-3 text-white" />
                            </Button.Slot>
                            {t('training.buttons.new-event')}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  {!training.id && (
                    <div className="bg-gray-5 text-dpm-14 relative my-1 flex items-center gap-3 rounded p-3">
                      <InfoIcon className="h-5 w-5" />
                      <div className="mt-[3px]">{t('unpublished-notice')}</div>
                    </div>
                  )}
                  {training.id && (
                    <div className="my-1">
                      <TrainingStats />
                    </div>
                  )}
                  <div className="my-1 bg-transparent">
                    <Accordion
                      title={
                        <Heading className="font-normal" size={HeadingSize.H5}>
                          {t('training.sidebar.material')}
                        </Heading>
                      }
                      active={true}
                      separationBorder="none"
                      bodyClassName="!px-0"
                      titleClassName="!px-0"
                    >
                      <div className="border-gray-5 rounded-sm bg-white p-4">
                        <span className="text-dpm-14">{t('material.empty')}</span>
                      </div>
                    </Accordion>
                  </div>
                  <div className={`${!trainingId ? 'pointer-events-none' : ''} my-1 py-2`}>
                    <Events onNewEvent={() => setShowNewEventModal(true)} />
                  </div>
                  <div className="my-1 py-2">
                    <Trainees />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EventModal open={showNewEventModal} onClose={() => setShowNewEventModal(false)} />
        </TrainingProvider>
      )}
    </PageLoader>
  );
};

export default TrainingPage;
